import { getTvGuideDefaultDate } from './tv-guide-helpers';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
import { isoDate as toIsoDate } from '../../utils/date-formatter';
const pageSection = 'tv';
export const slugToday = t => toSeoString(t('global.today').toLowerCase());
export const slugTomorrow = t => toSeoString(t('global.tomorrow').toLowerCase());
export const tvGuideRoute = i18n => ({
  pageId: 'tvGuide',
  pageType: 'overview',
  pageSection,
  path: `/${pageSection}/${i18n.t('routes.tvGuide')}/:isoDate?`,
  remixRoute: 'TvGuidePage.remix-route.tsx',
  showScrollTop: true
});
export const tvGuidePath = (i18n, isoDate = toIsoDate(getTvGuideDefaultDate())) => `/${pageSection}/${i18n.t('routes.tvGuide')}/${isoDate}`;